import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { isAdminRole } from '../../helpers/Utils'
import { Translation } from '../../helpers/Translation'
import {
  FOLDER_TYPES,
  PX_TO_REM,
  TEMPLATE_PAGE,
  TEST_ORDER_MAX_FILES_UPLOAD_CNT,
  WORKFLOW_PAGE,
} from '../../helpers/Constants'
import { Cookie } from '../../helpers/Cookie'

import Typography from '../../components/Typography'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import Dialog from '../../components/Dialog'

import DotAnimation from '../../layouts/Common/DotAnimation'
import MobileView from '../../layouts/Common/MobileView'
import BillingAddress from '../../layouts/Common/BillingAddress'

import GeneralSettings from './Partials/GeneralSettings'
import OrderFormTop from './Partials/OrderFormTop'
import DefaultSettings from './Partials/DefaultSettings'
import Clipping from './Partials/Clipping'
import Retouch from './Partials/Retouch'
import Resize from './Partials/Resize'
import Colours from './Partials/Colours'
import MoreOptions from './Partials/MoreOptions'
import Remark from './Partials/Remark'
import FileFormat from './Partials/FileFormat'
import InternalRemark from './Partials/InternalRemark'
import ImagesUpload from './Partials/ImagesUpload'
import Overview from './Partials/Overview'
import RetailSection from './Partials/RetailSection'
import Tabs from './Partials/Tabs'
import SaveTemplate from './Partials/SaveTemplate'

import { ReactComponent as InfoSvg } from '../../svg/info.svg'
import { ReactComponent as ClockSvg } from '../../svg/clock.svg'

import './index.scss'

const OrderForm = ({ isTestOrder, templateType, isIframeTemplate }) => {
  const getOrderFormTemplate = useStoreActions((actions) => actions.order.getOrderFormTemplate)
  const getOrderFormTemplateTest = useStoreActions((actions) => actions.order.getOrderFormTemplateTest)
  const getOrderFormFormat = useStoreActions((actions) => actions.order.getOrderFormFormat)
  const getEditTemplate = useStoreActions((actions) => actions.order.getEditTemplate)
  const getOrderFormPricing = useStoreActions((actions) => actions.order.getOrderFormPricing)
  const updateFormatActions = useStoreActions((actions) => actions.order.updateFormatActions)
  const updateLocaleFormatActions = useStoreActions((actions) => actions.order.updateLocaleFormatActions)
  const removeOrderFormFormat = useStoreActions((actions) => actions.order.removeOrderFormFormat)
  const removeOrderFormTemplate = useStoreActions((actions) => actions.order.removeOrderFormTemplate)
  const meAction = useStoreActions((actions) => actions.user.me)

  const isMobile = useStoreState((state) => state.layout.isMobile)
  const orderFormTemplate = useStoreState((state) => state.order.orderFormTemplate)
  const orderFormFormat = useStoreState((state) => state.order.orderFormFormat)
  const orderFormPricing = useStoreState((state) => state.order.orderFormPricing)
  const isAnyOrderPlaced = useStoreState((state) => state.order.isAnyOrderPlaced)
  const isAnyOrderInPreparation = useStoreState((state) => state.order.isAnyOrderInPreparation)
  const user = useStoreState((state) => state.user.user)

  const [activeTab, setActiveTab] = useState(1)
  const [warningId, setWarningId] = useState()
  const [updateValue, setUpdateValue] = useState({})
  const [orderFormatItem, setOrderFormatItem] = useState(null)
  const [orderFormatValue, setOrderFormatValue] = useState(null)
  const [isSelectedRetailId, setIsSelectedRetailId] = useState()
  const [images, setImages] = useState([])
  const [isTemplateLoadingFirst, setIsTemplateLoadingFirst] = useState(false)
  const [showIsTemplateWarningModal, setShowIsTemplateWarningModal] = useState(false)
  const [isWarningShown, setIsWarningShown] = useState(false)
  const [isShowTestOrderFirstLoadingModal, setIsShowTestOrderFirstLoadingModal] = useState(false)
  const [orderName, setOrderName] = useState('')
  const [isFetchFormats, setIsFetchFormats] = useState(true)
  const [showEmptyOrderNameDialog, setShowEmptyOrderNameDialog] = useState(false)
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false)
  const [isTemplateDisabled, setIsTemplateDisabled] = useState(false)

  const { id: editTemplateId } = useParams()

  const ids = [
    'zalando_ready_apparel_model',
    'zalando_ready_apparel_clipping',
    'zalando_ready_accessories',
    'amazon_ready',
    'amazon_ready_other',
    'ebay_ready',
  ]

  const retailSettingIds = [
    'retail_ready_apparel_model',
    'retail_ready_apparel_clipping',
    'retail_ready_accessories',
  ]

  const portalReadySettingIds = [
    'portal_settings',
    'portal_setting_zalando_ready',
    'portal_setting_amazon_ready',
    'portal_setting_ebay_ready',
    'portal_setting_retail_ready',
  ]

  const fetchTemplate = () => {
    if (isTestOrder) {
      getOrderFormTemplateTest()
      if (Cookie.getCookie('test_order_info_modal')) {
        setIsShowTestOrderFirstLoadingModal(false)
      } else {
        setIsShowTestOrderFirstLoadingModal(true)
      }
    } else if (templateType && editTemplateId) {
      getEditTemplate(editTemplateId)
    } else {
      getOrderFormTemplate()
    }
  }

  useEffect(() => {
    if (isIframeTemplate && Object.keys(user).length === 0) {
      meAction()
    }
  }, [isIframeTemplate])

  useEffect(() => {
    if (isIframeTemplate && Object.keys(user).length > 0 && !isAdminRole(user?.role_after_login)) {
      window.location.href = `/template/${editTemplateId}`
    }
  }, [isIframeTemplate, user, editTemplateId])

  useEffect(() => {
    // don't show edit template warning for admin role
    setIsTemplateLoadingFirst(!isAdminRole(user?.role_after_login) && templateType)
  }, [user])

  useEffect(() => {
    removeOrderFormTemplate()
    setActiveTab(1)
    document.getElementsByClassName('layout')?.[0]?.scrollTo(0, 0)
    fetchTemplate()

    return () => {
      removeOrderFormFormat()
      removeOrderFormTemplate()
    }
  }, [templateType, isTestOrder])

  // if there is orderFormTemplate, activeTab Id data is retrieved
  useEffect(() => {
    if (!isFetchFormats) {
      setIsFetchFormats(true)
      return
    }
    if ((orderFormTemplate?.template_formats?.[activeTab - 1]?.id !== undefined)) {
      removeOrderFormFormat()
      getOrderFormFormat(orderFormTemplate?.template_formats[activeTab - 1]?.id)
      if (orderFormTemplate?.folder_type === FOLDER_TYPES.WEB && !templateType) {
        setImages(orderFormTemplate?.web_images)
      }
      if (orderFormTemplate?.folder_type === FOLDER_TYPES.CSV) {
        setImages(orderFormTemplate?.csv_images)
      }
      if (orderFormTemplate?.folder_type === FOLDER_TYPES.FTP) {
        setImages([])
      }
      if (orderFormTemplate?.folder_type === FOLDER_TYPES.WETRANSFER) {
        // set 1 fake image
        setImages([{
          name: 'image_1.jpg',
        }])
      }

      if (orderFormTemplate?.template_id && !templateType && !isIframeTemplate) {
        getOrderFormPricing({ template_id: orderFormTemplate.template_id })
      }
    }
  }, [orderFormTemplate, activeTab])

  useEffect(() => {
    setIsSelectedRetailId(false)
    if (orderFormFormat?.retail_ready_apparel_model?.value
      || orderFormFormat?.retail_ready_apparel_clipping?.value
      || orderFormFormat?.retail_ready_accessories?.value) {
      setIsSelectedRetailId(true)
    }
  }, [orderFormFormat])

  useEffect(() => {
    if (Object.entries(user).length) {
      if (!user?.is_invoice_address && isAnyOrderPlaced) {
        setIsBillingModalOpen(true)
      }
    }
  }, [user, isAnyOrderPlaced])

  useEffect(() => {
    if (isIframeTemplate && isAdminRole(user?.role_after_login)) setIsTemplateDisabled(false)
    else setIsTemplateDisabled(orderFormTemplate?.is_template_disabled === true)
  }, [user, orderFormTemplate?.is_template_disabled])

  const handlePropertyChange = async (id, value) => {
    if (templateType && isTemplateLoadingFirst && !isWarningShown) {
      setShowIsTemplateWarningModal(true)
      setIsTemplateLoadingFirst(false)
      setIsWarningShown(true)
    } else {
      setShowIsTemplateWarningModal(false)
      updateLocaleFormatActions({ [id]: { ...(orderFormFormat[id]), value } })
      await updateFormatActions({
        id: orderFormTemplate?.template_formats[activeTab - 1]?.id,
        action_name: id,
        action_value: value,
      })

      // if we change portal setting(radio button), call getOrderFormTemplate again
      const portalSettingIds = [...ids, ...retailSettingIds]
      if (portalSettingIds.includes(id)) {
        setIsFetchFormats(false)
        fetchTemplate()
      }

      // if we click portal header setting and check if there is any value in portal setting
      if (portalReadySettingIds.includes(id)) {
        [...ids, ...retailSettingIds].forEach((item) => {
          if (orderFormFormat?.[item]?.value !== null && orderFormFormat?.[item]?.value !== false) {
            setIsFetchFormats(false)
            fetchTemplate()
            return false
          }
          return true
        })
      }

      if (!isIframeTemplate) {
        await getOrderFormPricing({ template_id: orderFormTemplate.template_id })
      }
    }
  }

  const onClickOutside = (e) => {
    if (e.target?.outerHTML?.includes('modal--wrap')) {
      setShowIsTemplateWarningModal(false)
      setIsTemplateLoadingFirst(false)
      setWarningId(false)
    }
  }

  // If there is warning_dependencies in warning_dependencies,
  // we remove the warning mode, otherwise the update request is sent directly.
  const isCheckWarningDependencies = (id, value) => {
    let isCheck = false

    setOrderFormatItem(id)
    setOrderFormatValue(value)

    if (id === 'format') {
      ids.forEach((item) => {
        if (orderFormFormat?.[item]?.warning_dependencies_format_exceptions?.length
          && orderFormFormat?.[item]?.value !== null && orderFormFormat?.[item]?.value !== false
          && !orderFormFormat?.[item]?.warning_dependencies_format_exceptions?.includes(value)) {
          setWarningId(id)
          setUpdateValue(value)
          isCheck = true
        }
      })
    } else {
      ids.forEach((item) => {
        if (orderFormFormat?.[item]?.warning_dependencies?.includes(id)
          && orderFormFormat?.[item]?.value !== null && orderFormFormat?.[item]?.value !== false) {
          setWarningId(id)
          setUpdateValue(value)
          isCheck = true
        }
      })
    }

    if (!isCheck) {
      handlePropertyChange(id, value).then(() => { })
    }
  }

  const handleImageChange = (templateImages) => {
    setImages(templateImages)
  }

  if (isMobile) {
    return (
      <MobileView
        label={Translation.new_order_mobile_message}
      />
    )
  }

  return Object.keys(user).length > 0 && (
    <div className="new-order-page">
      <div style={{ width: '100%' }}>
        {
          Object.keys(orderFormTemplate).length > 0 && (
            <>
              {
                isIframeTemplate && isAdminRole(user?.role_after_login) && Object.keys(orderFormTemplate).length > 0 && (
                  <GeneralSettings
                    orderFormTemplate={orderFormTemplate}
                  />
                )
              }
              <OrderFormTop
                orderFormTemplate={orderFormTemplate}
                isTestOrder={isTestOrder}
                templateType={templateType}
                onOrderNameChange={setOrderName}
                isIframeTemplate={isIframeTemplate}
              />
              <Tabs
                orderFormTemplate={orderFormTemplate}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isIframeTemplate={isIframeTemplate}
                templateType={templateType}
                editTemplateId={editTemplateId}
                isTestOrder={isTestOrder}
              />
              {
                Object.keys(orderFormFormat ?? {}).length > 0 && isSelectedRetailId && (
                  <>
                    <DefaultSettings
                      handlePropertyId={isCheckWarningDependencies}
                      orderFormatActions={orderFormFormat}
                      disableAll={isTemplateDisabled}
                    />
                    <RetailSection />
                  </>
                )
              }
              {
                Object.keys(orderFormFormat ?? {}).length > 0 && !isSelectedRetailId && (
                  <>
                    <FileFormat
                      handlePropertyId={isCheckWarningDependencies}
                      orderFormatActions={orderFormFormat}
                      isTestOrder={isTestOrder}
                      isIframeTemplate={isIframeTemplate}
                      disableAll={isTemplateDisabled}
                    />
                    <DefaultSettings
                      handlePropertyId={isCheckWarningDependencies}
                      isTemplateLoadingFirst={isTemplateLoadingFirst}
                      setShowIsTemplateWarningModal={setShowIsTemplateWarningModal}
                      orderFormatActions={orderFormFormat}
                      disableAll={isTemplateDisabled}
                    />
                    <Clipping
                      handlePropertyId={isCheckWarningDependencies}
                      orderFormatActions={orderFormFormat}
                      disableAll={isTemplateDisabled}
                      isTestOrder={isTestOrder}
                      templateType={templateType}
                    />
                    <Retouch
                      handlePropertyId={isCheckWarningDependencies}
                      orderFormatActions={orderFormFormat}
                      disableAll={isTemplateDisabled}
                    />
                    <Resize
                      handlePropertyId={isCheckWarningDependencies}
                      orderFormatActions={orderFormFormat}
                      disableAll={isTemplateDisabled}
                    />
                    <Colours
                      handlePropertyId={isCheckWarningDependencies}
                      orderFormatActions={orderFormFormat}
                      disableAll={isTemplateDisabled}
                    />
                    <MoreOptions
                      handlePropertyId={isCheckWarningDependencies}
                      orderFormatActions={orderFormFormat}
                      disableAll={isTemplateDisabled}
                    />
                    <Remark
                      handlePropertyId={isCheckWarningDependencies}
                      orderFormatActions={orderFormFormat}
                      disableAll={isTemplateDisabled}
                    />
                  </>
                )
              }
              {
                !((Object.keys(orderFormFormat ?? {}).length > 0)) && (
                  <div className="new-order-loading">
                    <DotAnimation />
                  </div>
                )
              }
              {
                !templateType && (
                  <SaveTemplate
                    templateId={orderFormTemplate.template_id}
                    orderName={orderName}
                    showEmptyOrderNameDialog={setShowEmptyOrderNameDialog}
                  />
                )
              }
              {
                (user.real_user_id || isAdminRole(user?.role_after_login)) && (
                  <InternalRemark orderFormTemplate={orderFormTemplate} />
                )
              }
              {
                (!templateType && !isAnyOrderInPreparation && !isIframeTemplate) && (
                  <ImagesUpload
                    templateId={orderFormTemplate?.template_id}
                    parallelTemplateId={orderFormTemplate?.parallel_template_id}
                    webImages={orderFormTemplate?.web_images}
                    onImageChange={handleImageChange}
                    folderType={orderFormTemplate?.folder_type}
                    testPic={orderFormTemplate?.test_pic}
                    csvImages={orderFormTemplate?.csv_images}
                    isTestOrder={isTestOrder}
                  />
                )
              }
              {
                !isAnyOrderInPreparation
                  && (Object.keys(orderFormPricing).length > 0)
                  && ((images.length > 0 && !isTestOrder)
                    || (isTestOrder && images.length <= TEST_ORDER_MAX_FILES_UPLOAD_CNT && images.length > 0))
                  && !templateType
                  && !isIframeTemplate
                  ? (
                    <Overview
                      orderFormTemplate={orderFormTemplate}
                      priceWidgetPosition={Object.keys(orderFormPricing?.errors ?? {}).length > 0 ? 'right' : ''}
                      orderFormPricing={orderFormPricing}
                      images={images}
                      isTestOrder={isTestOrder}
                      currency={user.currency}
                      orderName={orderName}
                      showEmptyOrderNameDialog={setShowEmptyOrderNameDialog}
                    />
                  ) : (
                    !templateType && (
                      isAnyOrderInPreparation ? (
                        <div className="overview-section">
                          <div className="previous-order-desc">
                            <div>
                              <ClockSvg />
                            </div>
                            <div>
                              <Typography variant="xs" label={Translation.order_in_process} />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="overview-section">
                          <div className="overview-section-top" style={{ marginBottom: '0' }}>
                            <Typography variant="xs" font="semibold" label={Translation.overview} />
                            <Typography variant="xs" label={Translation.overview_empty_text} />
                          </div>
                        </div>
                      )
                    )
                  )
              }
              {
                templateType && orderFormTemplate?.is_template_disabled === true && !isIframeTemplate && (
                  <div className="test-order-information">
                    <div className="test-order-information-title">
                      <InfoSvg />
                      <Typography variant="h6" font="semibold" label={Translation.information} />
                    </div>
                    <div className="test-order-information-text">
                      <Typography variant="xs" label={Translation.template_can_not_be_edited} />
                    </div>
                  </div>
                )
              }
              {
                templateType && orderFormTemplate?.folder_id !== null && !isIframeTemplate && (
                  <div className="test-order-information">
                    <div className="test-order-information-title">
                      <InfoSvg />
                      <Typography variant="h6" font="semibold" label={Translation.information} />
                    </div>
                    <div className="test-order-information-text">
                      <Typography variant="xs" label={Translation.edit_template_bottom_text} />
                    </div>
                  </div>
                )
              }
            </>
          )
        }
      </div>
      {
        Object.keys(orderFormPricing?.errors ?? {}).length > 0 && (
          <div className="error-widget">
            {
              Object.keys(orderFormPricing?.errors ?? {}).map((key, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="error-widget-section">
                  <Typography variant="xs" font="semibold" label={orderFormPricing?.errors[key].header} />
                  <ul>
                    {
                      orderFormPricing?.errors[key].errors.map((message, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={i}>
                          <Typography variant="body1" label={message.error} />
                        </li>
                      ))
                    }
                  </ul>
                </div>
              ))
            }
          </div>
        )
      }
      {
        warningId && (
          <div className="new-order-modal">
            <Modal
              hasCloseIcon
              isShown
              onClickOutside={onClickOutside}
              onClickCloseIcon={() => { setWarningId(false) }}
              onClickEscClose={() => { setWarningId(false) }}
            >
              <div className="new-order-modal-title">
                <Typography
                  variant="h6"
                  font="semibold"
                  lineHeight={PX_TO_REM['25']}
                  label={Translation.attention}
                />
              </div>
              <div className="new-order-modal-content">
                <Typography
                  variant="xs"
                  containsHtml
                  label={Translation.new_order_change_modal_text.replace('{id}', orderFormFormat?.[warningId]?.label)}
                />
              </div>
              <div className="new-order-modal-buttons">
                <Button type="contained" color="primary" label={Translation.cancel} onClick={() => setWarningId(false)} />
                <Button
                  color="primary"
                  label={Translation.yes}
                  onClick={() => {
                    handlePropertyChange(warningId, updateValue).then(() => { })
                    setWarningId()
                    setUpdateValue()
                  }}
                />
              </div>
            </Modal>
          </div>
        )
      }
      {
        showIsTemplateWarningModal && templateType && (
          <div className="new-order-modal">
            <Modal
              hasCloseIcon
              isShown
              onClickOutside={() => { }}
              onClickEscClose={() => {
                setShowIsTemplateWarningModal(false)
                setIsTemplateLoadingFirst(true)
                setIsWarningShown(false)
              }}
              onClickCloseIcon={() => {
                setShowIsTemplateWarningModal(false)
                setIsTemplateLoadingFirst(true)
                setIsWarningShown(false)
              }}
            >
              <div className="new-order-modal-title">
                <Typography
                  variant="h6"
                  font="semibold"
                  lineHeight={PX_TO_REM['25']}
                  label={Translation.attention}
                />
              </div>
              <div className="new-order-modal-content">
                <Typography variant="xs" label={Translation.edit_template_warning_text} />
              </div>
              <div className="new-order-modal-buttons">
                <Button
                  label={Translation.cancel}
                  type="contained"
                  onClick={() => {
                    setShowIsTemplateWarningModal(false)
                    setIsTemplateLoadingFirst(true)
                    setIsWarningShown(false)
                  }}
                />
                <Button
                  label={Translation.ok}
                  onClick={() => handlePropertyChange(orderFormatItem, orderFormatValue)}
                />
              </div>
            </Modal>
          </div>
        )
      }
      {
        isShowTestOrderFirstLoadingModal && isTestOrder && (
          <div className="new-order-modal">
            <Modal
              hasCloseIcon={false}
              isShown
              onClickEscClose={() => setIsShowTestOrderFirstLoadingModal(false)}
            >
              <div className="new-order-modal-title">
                <Typography
                  variant="h6"
                  font="semibold"
                  lineHeight={PX_TO_REM['25']}
                  label={Translation.test_order_first_modal_title}
                />
              </div>
              <ul className="test-order-modal-content">
                <li><Typography variant="s" label={Translation.test_order_first_modal_content_1} /></li>
                <li><Typography variant="s" label={Translation.test_order_first_modal_content_2} /></li>
                <li><Typography variant="s" label={Translation.test_order_first_modal_content_3} /></li>
                <li><Typography variant="s" label={Translation.test_order_first_modal_content_4} /></li>
              </ul>
              <div className="new-order-modal-buttons">
                <Button
                  label={Translation.place_test_order}
                  onClick={() => {
                    Cookie.setCookie('test_order_info_modal', 1)
                    setIsShowTestOrderFirstLoadingModal(false)
                  }}
                />
              </div>
            </Modal>
          </div>
        )
      }
      {
        showEmptyOrderNameDialog && (
        <div className="new-order-modal">
          <Dialog
            isShown={showEmptyOrderNameDialog}
            title={`${Translation.attention}!`}
            content={(<Typography variant="xs" label={Translation.empty_order_name_check_modal_text} />)}
            yesLabel={Translation.ok}
            onClickCancel={() => setShowEmptyOrderNameDialog(false)}
            onClickYes={() => {
              setShowEmptyOrderNameDialog(false)
              // scroll to top and focus on order name input
              window.scrollTo(0, 0)
              document.getElementById('new-order-name').focus()
            }}
            hasOnlyYes
            isWarning
          />
        </div>
        )
      }
      {isBillingModalOpen && (
        <div
          className="billing-address-modal"
          style={{ display: isBillingModalOpen ? 'block' : 'none' }}
        >
          <BillingAddress
            isOpen={isBillingModalOpen}
            closeModal={() => setIsBillingModalOpen(false)}
            modalTitle={Translation.provide_billing_address_order}
            isDashboard
          />
        </div>
      )}
    </div>
  )
}

OrderForm.propTypes = {
  isTestOrder: PropTypes.bool,
  templateType: PropTypes.oneOf(['', TEMPLATE_PAGE, WORKFLOW_PAGE]),
  isIframeTemplate: PropTypes.bool,
}

OrderForm.defaultProps = {
  isTestOrder: false,
  templateType: '',
  isIframeTemplate: false,
}

export default OrderForm
