import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ErrorBoundary } from 'react-error-boundary'

import { axiosInstance as axios } from '../helpers/Axios'
import { Translation } from '../helpers/Translation'
import {
  DEFAULT_PAGE,
  LOGIN_PAGE,
  PAYMENT_SUCCESS_PAGE,
  CHECKOUT_PAGE,
  NEW_ORDER_PAGE,
  ORDER_SUCCESS_PAGE,
  IMAGE_SEARCH_PAGE,
  TEST_ORDER_PAGE,
  TEMPLATES_PAGE,
  TEMPLATE_PAGE,
  WORKFLOWS_PAGE,
  WORKFLOW_PAGE,
} from '../helpers/Constants'
import { isAuthenticated } from '../helpers/Utils'
import { API_ENDPOINTS } from '../helpers/Urls'
import { isLocal } from '../helpers/Cookie'

import LayoutNotLogged from '../layouts/LayoutNotLogged'
// eslint-disable-next-line import/no-cycle
import Layout from '../layouts/Layout'

import Login from '../pages/Login'
import Register from '../pages/Register'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
// eslint-disable-next-line import/no-cycle
import Support from '../pages/Support'
import Dashboard from '../pages/Dashboard'
import OrderForm from '../pages/OrderForm'
import OrderSuccess from '../pages/OrderSuccess'
import PaymentSuccess from '../pages/PaymentSuccess'
import Templates from '../pages/Templates'
import Workflows from '../pages/Workflows'
import Ftp from '../pages/Ftp'
import Error from '../pages/Error'
import Profile from '../pages/Profile'
import Checkout from '../pages/Checkout'
import SetPassword from '../pages/SetPassword'
import MailingUnsubscribe from '../pages/MailingUnsubscribe'
import ErrorFake from '../pages/ErrorFake'
import ApiOrderForm from '../pages/ApiOrderForm'
import SwitchUser from '../pages/SwitchUser'
import ErrorJs from '../pages/ErrorJs'
import ImageSearch from '../pages/ImageSearch'
import PublicGallerySettings from '../pages/PublicGallerySettings'

import { ReactComponent as DashboardISvg } from '../svg/dashboard.svg'
import { ReactComponent as DashboardWhiteSvg } from '../svg/dashboard_white.svg'
import { ReactComponent as OrderFormSvg } from '../svg/new_order.svg'
import { ReactComponent as OrderFormWhiteSvg } from '../svg/new_order_white.svg'
import { ReactComponent as FTPOrderSvg } from '../svg/live.svg'
import { ReactComponent as WhiteFTPOrderSvg } from '../svg/live-white.svg'
import { ReactComponent as TemplatesSvg } from '../svg/templates.svg'
import { ReactComponent as TemplatesWhiteSvg } from '../svg/templates_white.svg'
import { ReactComponent as WorkflowsSvg } from '../svg/workflows.svg'
import { ReactComponent as WorkflowsWhiteSvg } from '../svg/workflows_white.svg'
import { ReactComponent as FtpSvg } from '../svg/ftp.svg'
import { ReactComponent as FtpWhiteSvg } from '../svg/ftp_white.svg'
import { ReactComponent as ProfileSvg } from '../svg/profile.svg'
import { ReactComponent as ProfileWhiteSvg } from '../svg/profile_white.svg'
import { ReactComponent as CircleCrossSvg } from '../svg/circle_cross.svg'

const logJsErrorToApi = (error, info) => {
  if (isLocal) return

  axios.post(API_ENDPOINTS.jsError, {
    message: error.message,
    file: error?.fileName,
    line: error?.lineNumber,
    column: error?.columnNumber,
    stack: info.componentStack,
    userAgent: navigator.userAgent,
    href: window.location.href,
    isFromReact: true,
  })
}

const pages = [
  {
    path: LOGIN_PAGE,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <LayoutNotLogged
          pageName="login"
          title={Translation.login}
          isHalf
          isHalfRight
        >
          <Login />
        </LayoutNotLogged>
      </ErrorBoundary>
    ),
  },
  {
    path: '/register',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <LayoutNotLogged
          pageName="register"
          title={Translation.register}
          isHeader
          isHalf
          isHalfRight
        >
          <Register />
        </LayoutNotLogged>
      </ErrorBoundary>
    ),
  },
  {
    path: '/register-and-test',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <LayoutNotLogged
          pageName="register_and_test"
          title={Translation.register_for_free_test}
          isHeader
          isHalf
          isHalfRight
        >
          <Register isRegisterToTest />
        </LayoutNotLogged>
      </ErrorBoundary>
    ),
  },
  {
    path: '/forgot',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <LayoutNotLogged
          pageName="forgot"
          title={Translation.forgot_password_question}
          isHeader
        >
          <ForgotPassword />
        </LayoutNotLogged>
      </ErrorBoundary>
    ),
  },
  {
    path: '/reset-your-password/:token',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <LayoutNotLogged
          pageName="reset-your-password"
          title={Translation.reset_password}
          isHeader
        >
          <ResetPassword />
        </LayoutNotLogged>
      </ErrorBoundary>
    ),
  },
  {
    path: '/set-your-password/:token',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <LayoutNotLogged
          pageName="set-your-password"
          title={Translation.reset_password}
          isHeader
        >
          <SetPassword />
        </LayoutNotLogged>
      </ErrorBoundary>
    ),
  },
  {
    path: 'mailing/unsubscribe/:lang/:user_account_id_code/:mailing_id_code',
    element: isAuthenticated() ? (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout>
          <MailingUnsubscribe />
        </Layout>
      </ErrorBoundary>
    ) : (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <LayoutNotLogged isHeader>
          <MailingUnsubscribe />
        </LayoutNotLogged>
      </ErrorBoundary>
    ),
  },
  {
    path: '/generate-random-js-error',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="generate-random-js-error"
          title="Random JS error"
        >
          <ErrorFake />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: '/generate-random-js-error-not-logged',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <LayoutNotLogged
          pageName="generate-random-js-error"
          title="Random JS error"
          isHeader
        >
          <ErrorFake />
        </LayoutNotLogged>
      </ErrorBoundary>
    ),
  },
  {
    path: '/support',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="support"
          title={Translation.support}
        >
          <Support />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: CHECKOUT_PAGE,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="checkout"
          title={Translation.checkout}
        >
          <Checkout />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: ORDER_SUCCESS_PAGE,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="new_order"
          title={Translation.new_order}
        >
          <OrderSuccess />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: PAYMENT_SUCCESS_PAGE,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="order_success"
          title={Translation.checkout}
        >
          <PaymentSuccess />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    pageName: 'error',
    path: '*',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <LayoutNotLogged
          pageName="error"
          title={Translation.error_not_found}
          isHeader
          noAuth
        >
          <Error
            errorType={Translation.error_type_404}
            errorTitle={Translation.error_not_found}
            errorDescr={Translation.error_doesnt_exist}
            btnLabel={Translation.error_btn_take_me_home}
          />
        </LayoutNotLogged>
      </ErrorBoundary>
    ),
  },
  {
    path: '/email/confirmation/:token/:id',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="dashboard"
          title={Translation.dashboard}
        >
          <Dashboard />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: '/switch_to_user_account/:id',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout>
          <SwitchUser />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: `${TEMPLATE_PAGE}/:id`,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="template_edit"
          title={`${Translation.template}`}
        >
          <OrderForm templateType={TEMPLATE_PAGE} />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: `${WORKFLOW_PAGE}/:id`,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="workflow_edit"
          title={`${Translation.workflow}`}
        >
          <OrderForm templateType={WORKFLOW_PAGE} />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: `/iframe${WORKFLOW_PAGE}/:id`,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <OrderForm templateType={WORKFLOW_PAGE} isIframeTemplate />
      </ErrorBoundary>
    ),
  },
  {
    path: `/iframe${TEMPLATE_PAGE}/:id`,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <OrderForm templateType={WORKFLOW_PAGE} isIframeTemplate />
      </ErrorBoundary>
    ),
  },
  {
    path: TEST_ORDER_PAGE,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="test_order"
          title={Translation.test_order}
        >
          <OrderForm isTestOrder />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: IMAGE_SEARCH_PAGE,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="image_search"
          title={Translation.image_search}
        >
          <ImageSearch />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: '/public-gallery-settings/:id',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="public_gallery_settings"
          title={Translation.public_gallery_settings}
        >
          <PublicGallerySettings />
        </Layout>
      </ErrorBoundary>
    ),
  },
  {
    path: '/public-gallery-settings/new/:template_id',
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="public_gallery_settings"
          title={Translation.public_gallery_settings}
        >
          <PublicGallerySettings />
        </Layout>
      </ErrorBoundary>
    ),
  },
]

export const sidebarPages = [
  {
    pageName: 'dashboard',
    path: DEFAULT_PAGE,
    label: Translation.dashboard,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="dashboard"
          title={Translation.dashboard}
        >
          <Dashboard />
        </Layout>
      </ErrorBoundary>
    ),
    icon: <DashboardISvg />,
    iconWhite: <DashboardWhiteSvg />,
  },
  {
    pageName: 'new_order',
    path: NEW_ORDER_PAGE,
    label: Translation.new_order,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="new_order"
          title={Translation.new_order}
        >
          <OrderForm />
        </Layout>
      </ErrorBoundary>
    ),
    icon: <OrderFormSvg />,
    iconWhite: <OrderFormWhiteSvg />,
    hideOnMobile: true,
  },
  {
    pageName: 'api_order',
    path: '/api/order',
    label: Translation.live_ftp_order,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="api_order"
          title={Translation.live_ftp_order}
        >
          <ApiOrderForm />
        </Layout>
      </ErrorBoundary>
    ),
    icon: <FTPOrderSvg />,
    iconWhite: <WhiteFTPOrderSvg />,
    hideOnMobile: true,
  },
  {
    pageName: 'templates',
    path: TEMPLATES_PAGE,
    label: Translation.templates,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="templates"
          title={Translation.templates}
        >
          <Templates />
        </Layout>
      </ErrorBoundary>
    ),
    icon: <TemplatesSvg />,
    iconWhite: <TemplatesWhiteSvg />,
  },
  {
    pageName: 'workflows',
    path: WORKFLOWS_PAGE,
    label: Translation.workflows,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="workflows"
          title={Translation.workflows}
        >
          <Workflows />
        </Layout>
      </ErrorBoundary>
    ),
    icon: <WorkflowsSvg />,
    iconWhite: <WorkflowsWhiteSvg />,
  },
  {
    pageName: 'ftp',
    path: '/ftp',
    label: Translation.ftp_connection,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="ftp"
          title={Translation.ftp_connection}
        >
          <Ftp />
        </Layout>
      </ErrorBoundary>
    ),
    icon: <FtpSvg />,
    iconWhite: <FtpWhiteSvg />,
  },
  {
    pageName: 'profile',
    path: '/profile',
    label: Translation.profile,
    element: (
      <ErrorBoundary fallback={<ErrorJs />} onError={logJsErrorToApi}>
        <Layout
          pageName="profile"
          title={Translation.profile}
        >
          <Profile />
        </Layout>
      </ErrorBoundary>
    ),
    icon: <ProfileSvg />,
    iconWhite: <ProfileWhiteSvg />,
  },
  {
    pageName: 'back_to_my_user',
    label: Translation.back_to_my_user,
    icon: <CircleCrossSvg />,
    iconWhite: <CircleCrossSvg />,
  },
]

export const Router = createBrowserRouter([
  ...pages, ...sidebarPages,
])
