import { action, thunk } from 'easy-peasy'

import { axiosInstance as axios } from '../helpers/Axios'
import { API_ENDPOINTS } from '../helpers/Urls'
import { Translation } from '../helpers/Translation'

const OrderStore = {
  orderId: null,
  orders: [],
  ordersCount: 0,
  isAnyOrderPlaced: false,
  isAnyOrderInPreparation: false,
  orderDetails: [],
  orderDetailsImages: {},
  orderSearchImages: [],
  orderFormTemplate: {},
  orderFormFormat: {},
  orderFormPricing: {},
  unpaidData: null,
  dashboardData: null,
  isOrderDetailDataAgain: false,
  apiErrors: '',
  createdOrderId: null,
  folderType: null,
  folderId: null,
  orderFormPageError: {},

  setOrderFormPageError: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orderFormPageError = {
      ...state.orderFormPageError,
      ...payload,
    }
  }),

  removeOrderFormPageError: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.orderFormPageError = {}
  }),

  setOrderId: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orderId = payload
  }),

  setUnpaidData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.unpaidData = payload
  }),

  setOrders: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orders = payload
  }),

  updateOrderRow: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orders = state.orders.map((order) => {
      if (order.id === payload.id) {
        return {
          ...payload,
        }
      }
      return order
    })
  }),

  setOrdersCount: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.ordersCount = payload
  }),

  setIsAnyOrderPlaced: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isAnyOrderPlaced = payload
  }),

  setCreatedOrderId: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.createdOrderId = payload
  }),

  setFolderType: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.folderType = payload
  }),

  setIsAnyOrderInPreparation: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isAnyOrderInPreparation = payload
  }),

  updateOrderIsPrioritised: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orders = state.orders.map((order) => {
      if (order.id === payload.id) {
        return {
          ...order,
          is_prioritised: payload.is_prioritised,
        }
      }
      return order
    })
  }),

  setOrderDetails: action((state, payload) => {
    const existingOrderIndex = state.orderDetails.findIndex((order) => order.id === payload.id)

    if (existingOrderIndex !== -1) {
      // eslint-disable-next-line no-param-reassign
      state.orderDetails[existingOrderIndex] = payload
    } else {
      state.orderDetails.push(payload)
    }
  }),

  deleteOrderDetails: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orderDetails = state.orderDetails.filter((order) => order.id !== payload)
    // eslint-disable-next-line no-param-reassign
    state.orderDetailsImages[payload] = []
  }),

  removeOrderDetailsState: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.orderDetails = []
    // eslint-disable-next-line no-param-reassign
    state.orderDetailsImages = {}
  }),

  setIsOrderDetailDataAgain: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isOrderDetailDataAgain = payload
  }),

  setOrderDetailsImages: action((state, payload) => {
    if (payload.page === 1) {
      // eslint-disable-next-line no-param-reassign
      state.orderDetailsImages = {
        ...state.orderDetailsImages,
        [payload.id]: payload.images.images,
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      state.orderDetailsImages = {
        ...state.orderDetailsImages,
        [payload.id]: [...(state.orderDetailsImages[payload.id] || []), ...(payload.images.images || [])],
      }
    }
  }),

  setOrderSearchImages: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orderSearchImages = payload
  }),

  setDashboardData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.dashboardData = payload
  }),

  setOrderFormTemplate: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orderFormTemplate = payload
  }),

  setFolderId: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.folderId = payload
  }),

  setOrderFormFormat: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orderFormFormat = payload
  }),

  updateLocaleFormatActions: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orderFormFormat = {
      ...state.orderFormFormat,
      ...payload,
    }
  }),

  setOrderFormPricing: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orderFormPricing = payload
  }),

  removeOrderFormFormat: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.orderFormFormat = {}
  }),

  removeOrderFormTemplate: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.orderFormTemplate = {}
  }),

  addApiErrors: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = payload
  }),

  getUnpaidData: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.unpaid_details_checkout)

      if (response.data.result.success) {
        actions.setUnpaidData(response.data.result)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }),

  getOrders: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(API_ENDPOINTS.orders, { params: payload })

      if (response.data.result.success) {
        actions.setOrders(response.data.result.orders)
        actions.setOrdersCount(response.data.result.orders_count)
        actions.setIsAnyOrderPlaced(response.data.result.is_any_order_placed)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getOrderDetails: thunk(async (actions, payload) => {
    const orderDetailsApiUrl = API_ENDPOINTS.order_details.replace('{id}', payload)
    try {
      const response = await axios.get(orderDetailsApiUrl)

      if (response.data.result.success) {
        actions.setOrderDetails({ ...response.data.result, id: payload })
        actions.updateOrderRow(response.data.result.order_row)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getOrderDetailsImages: thunk(async (actions, { id, params }) => {
    const orderDetailsImagesApiUrl = API_ENDPOINTS.order_details_images.replace('{id}', id)
    try {
      const response = await axios.get(orderDetailsImagesApiUrl, { params })
      if (response.data.result.success) {
        actions.setOrderDetailsImages({ id, images: response.data.result, page: params.page })
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getOrderTestImageConfirm: thunk(async (actions, payload) => {
    const orderTestImageConfirmApiUrl = API_ENDPOINTS.order_test_image_confirm.replace('{id}', payload.id)
    try {
      const response = await axios.patch(orderTestImageConfirmApiUrl, { status: payload.status, comment: payload.comment })

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  getOrderSearchImages: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(API_ENDPOINTS.order_search_images, { params: payload })

      if (response.data.result.success) {
        actions.setOrderSearchImages(response.data.result.images)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }),

  getDashboardData: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.dashboard)

      if (response.data.result.success) {
        actions.setDashboardData(response.data.result)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }),

  getPrioritiseOrder: thunk(async (actions, payload) => {
    const prioritiseOrderApiUrl = API_ENDPOINTS.order_prioritise.replace('{id}', payload.id)
    try {
      const response = await axios.patch(prioritiseOrderApiUrl, { status: payload.status })

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  getOrderAcceptPrice: thunk(async (actions, payload) => {
    const orderAcceptPriceApiUrl = API_ENDPOINTS.order_accept_price.replace('{id}', payload.id)
    try {
      const response = await axios.patch(orderAcceptPriceApiUrl)

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  getOrderRating: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.rating, payload)

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  getEkomiWebhook: thunk(async (actions, payload) => {
    try {
      const response = await axios.patch(API_ENDPOINTS.ekomi_webhook.replace('{id}', payload.id), {
        rating: payload.rating, review: payload.review,
      })

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  uploadImage: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.image_upload, payload, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  deleteImage: thunk(async (actions, payload) => {
    try {
      const response = await axios.delete(API_ENDPOINTS.image_upload, { data: payload })

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  getSelectedTestImage: thunk(async (actions, payload) => {
    const selectedTestImageApiUrl = API_ENDPOINTS.selected_test_image.replace('{id}', payload.template_id)
    try {
      const response = await axios.patch(selectedTestImageApiUrl, { filename: payload.filename })

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  updateTemplateValue: thunk(async (actions, payload) => {
    try {
      const response = await axios.patch(API_ENDPOINTS.update_template_value
        .replace('{id}', payload.template_id), { id: payload.id, value: payload.value })

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  resetFormatActions: thunk(async (actions, payload) => {
    try {
      const response = await axios.patch(API_ENDPOINTS.format_actions_reset.replace('{id}', payload))

      if (response.data.result.success) {
        actions.removeOrderFormFormat()
        actions.setOrderFormFormat(response.data.result.order_format_actions)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }),

  getCsvUpload: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.csv_upload, payload)

      return response.data.result
    } catch (error) {
      return false
    }
  }),

  getOrderFormTemplate: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.new_order_template)

      if (response.data.result.success) {
        actions.setOrderFormTemplate(response.data.result)
        actions.setFolderId(response.data.result.folder_id)
        actions.setIsAnyOrderPlaced(response.data.result.is_any_order_placed)
        actions.setIsAnyOrderInPreparation(response.data.result.is_any_order_in_preparation)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getOrderFormTemplateTest: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.test_order_template)

      if (response.data.result.success) {
        actions.setOrderFormTemplate(response.data.result)
        actions.setFolderId(response.data.result.folder_id)
        actions.setIsAnyOrderPlaced(response.data.result.is_any_order_placed)
        actions.setIsAnyOrderInPreparation(response.data.result.is_any_order_in_preparation)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }),

  getEditTemplate: thunk(async (actions, payload) => {
    const templateEditApiUrl = API_ENDPOINTS.update_template_value.replace('{id}', payload)
    try {
      const response = await axios.get(templateEditApiUrl)

      if (response.data.result.success) {
        actions.setOrderFormTemplate(response.data.result)
        actions.setFolderId(response.data.result.folder_id)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }),

  getOrderFormFormat: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(API_ENDPOINTS.format_action.replace('{id}', payload))

      actions.removeOrderFormPageError()

      if (response.data.result.success) {
        actions.setOrderFormFormat(response.data.result.order_format_actions)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  updateFormatActions: thunk(async (actions, payload) => {
    try {
      const response = await axios.patch(API_ENDPOINTS.update_format_action.replace('{id}', payload.id), {
        action_name: payload.action_name, action_value: payload.action_value,
      })

      actions.removeOrderFormPageError()

      if (response.data.result.success) {
        actions.removeOrderFormFormat()
        actions.setOrderFormFormat(response.data.result.order_format_actions)
        actions.setOrderFormPageError({ [payload.action_name]: null })
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)

        if (error.response.data.result.error_message) {
          actions.setOrderFormPageError({ [payload.action_name]: error.response.data.result.error_message })
        }
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getOrderFormPricing: thunk(async (actions, payload) => {
    const orderFormPricingApiUrl = API_ENDPOINTS.order_template_pricing
      .replace('{template_id}', payload.template_id)

    try {
      const response = await axios.get(orderFormPricingApiUrl)

      if (response.data.result.success) {
        actions.setOrderFormPricing(response.data.result)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }),

  addOrderFormFormat: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.add_delete_new_format.replace('{id}', payload))

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  deleteOrderFormFormat: thunk(async (actions, payload) => {
    try {
      const response = await axios.delete(API_ENDPOINTS.add_delete_new_format.replace('{id}', payload))

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  addCouponCode: thunk(async (actions, payload) => {
    try {
      const response = await axios.patch(API_ENDPOINTS.add_coupon_code, { code: payload })

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  deleteCouponCode: thunk(async (actions, payload) => {
    try {
      const response = await axios.delete(API_ENDPOINTS.delete_coupon_code, { data: payload })

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  createOrder: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.order_create, payload)

      return response.data.result
    } catch (error) {
      return false
    }
  }),

}

export default OrderStore
