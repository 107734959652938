import React from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'
import {
  Accordion, AccordionDetails, AccordionSummary, Tooltip,
} from '@mui/material'

import { Translation } from '../../../../helpers/Translation'
import { isAdminRole } from '../../../../helpers/Utils'

import Select from '../../../../components/Select'
import Input from '../../../../components/Input'

import ColumnSwitch from '../../Common/ColumnSwitch'
import SectionTop from '../../Common/SectionTop'

import { ReactComponent as MoreOptionsSvg } from '../../../../svg/more_options.svg'
import { ReactComponent as InfoSvg } from '../../../../svg/info.svg'

import './index.scss'

const MoreOptions = ({ handlePropertyId, orderFormatActions, disableAll }) => {
  const user = useStoreState((actions) => actions.user.user)
  const orderFormPageError = useStoreState((state) => state.order.orderFormPageError)
  const updateLocaleFormatActions = useStoreActions((actions) => actions.order.updateLocaleFormatActions)

  const shadowValues = {
    shadow_type_original: Translation.original,
    shadow_type_new: Translation.new,
  }

  const mirroringValues = {
    mirroring_type_original: Translation.original,
    mirroring_type_new: Translation.new,
  }

  return (
    <section className={`new-order-section section-more-options ${orderFormatActions?.more?.value ? 'active' : ''}`}>
      <Accordion expanded={!!orderFormatActions?.more?.value} className="accordion">
        <AccordionSummary className="accordion-summary">
          <SectionTop
            showContent={!!orderFormatActions?.more?.value}
            onChange={(id, value) => handlePropertyId(id, value)}
            id="more"
            icon={<MoreOptionsSvg />}
            defaultChecked={orderFormatActions?.more?.value !== null}
            labelText={orderFormatActions?.more?.label}
            badgeText={orderFormatActions?.more?.price}
            disabled={disableAll}
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <div className="section-content">
            <div className="section-content-columns">
              <div className="section-content-column">
                <ColumnSwitch
                  id="shadow"
                  labelText={orderFormatActions?.shadow?.label}
                  modalText={Translation.more_options_shadow_modal}
                  badgeText={orderFormatActions?.shadow?.price}
                  defaultChecked={orderFormatActions?.shadow?.value !== null}
                  onChange={(id, value) => handlePropertyId(id, value)}
                  disabled={disableAll}
                />
                <div className="column-content" style={{ display: orderFormatActions.shadow.value ? 'block' : 'none' }}>
                  <Select
                    isEmptyOption={false}
                    values={shadowValues}
                    value={orderFormatActions?.shadow_type_selected?.value || ''}
                    onChange={(e) => handlePropertyId('shadow_type_selected', e.target.value)}
                    itemClass="new-order-select-item"
                    disabled={disableAll}
                  />
                </div>
              </div>
              <div className="section-content-column">
                <ColumnSwitch
                  id="mirroring"
                  labelText={orderFormatActions?.mirroring?.label}
                  modalText={Translation.more_options_mirroring_modal}
                  badgeText={orderFormatActions?.mirroring?.price}
                  defaultChecked={orderFormatActions?.mirroring?.value !== null}
                  onChange={(id, value) => handlePropertyId(id, value)}
                  disabled={disableAll}
                />
                <div className="column-content" style={{ display: orderFormatActions.mirroring.value ? 'block' : 'none' }}>
                  <Select
                    isEmptyOption={false}
                    values={mirroringValues}
                    value={orderFormatActions?.mirroring_type_selected?.value || ''}
                    onChange={(e) => handlePropertyId('mirroring_type_selected', e.target.value)}
                    itemClass="new-order-select-item"
                    disabled={disableAll}
                  />
                </div>
              </div>
              <div className="section-content-column">
                <ColumnSwitch
                  id="rename"
                  labelText={orderFormatActions?.rename?.label}
                  modalText={Translation.more_options_rename_modal}
                  badgeText={orderFormatActions?.rename?.price}
                  defaultChecked={orderFormatActions?.rename?.value !== null}
                  onChange={(id, value) => handlePropertyId(id, value)}
                  disabled={disableAll}
                />
                {
                  isAdminRole(user?.role_after_login) && (
                    <div className="column-content" style={{ display: orderFormatActions?.rename?.value ? 'block' : 'none' }}>
                      <div className="column-inputs">
                        <div className="column-input">
                          <Input
                            value={orderFormatActions?.image_matching_regexp_input?.value || ''}
                            onBlur={(e) => handlePropertyId('image_matching_regexp_input', e.target.value)}
                            onChange={(e) => {
                              updateLocaleFormatActions({
                                image_matching_regexp_input:
                                  { ...orderFormatActions?.image_matching_regexp_input, value: e.target.value },
                              })
                            }}
                            placeholder="Regexp input"
                            disabled={disableAll}
                            isError={orderFormPageError.image_matching_regexp_input ?? false}
                            endAdornment={orderFormPageError.image_matching_regexp_input ? (
                              <Tooltip
                                className="info-tooltip"
                                placement="right"
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                title={orderFormPageError.image_matching_regexp_input}
                                arrow
                              >
                                <InfoSvg />
                              </Tooltip>
                            ) : null}
                          />
                        </div>
                        <div className="column-input">
                          <Input
                            value={orderFormatActions?.image_matching_regexp_output?.value || ''}
                            onChange={(e) => {
                              updateLocaleFormatActions({
                                image_matching_regexp_output:
                                  { ...orderFormatActions?.image_matching_regexp_output, value: e.target.value },
                              })
                            }}
                            onBlur={(e) => handlePropertyId('image_matching_regexp_output', e.target.value)}
                            placeholder="Regexp output"
                            disabled={disableAll}
                            isError={orderFormPageError.image_matching_regexp_output ?? false}
                            endAdornment={orderFormPageError.image_matching_regexp_output ? (
                              <Tooltip
                                className="info-tooltip"
                                placement="right"
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                title={orderFormPageError.image_matching_regexp_output}
                                arrow
                              >
                                <InfoSvg />
                              </Tooltip>
                            ) : null}
                          />
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </section>
  )
}

MoreOptions.propTypes = {
  handlePropertyId: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  orderFormatActions: PropTypes.objectOf(PropTypes.any),
  disableAll: PropTypes.bool,
}

MoreOptions.defaultProps = {
  orderFormatActions: {},
  disableAll: false,
}

export default MoreOptions
