import React, { useState, useEffect } from 'react'
import { useStoreActions } from 'easy-peasy'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'
import Switch from '../../../../components/Switch'
import RadioGroup from '../../../../components/RadioGroup'

import SectionTop from '../../Common/SectionTop'
import InfoModal from '../../Common/InfoModal'
import OrderFormModal from '../../Common/OrderFormModal'

import { ReactComponent as LayersSvg } from '../../../../svg/layers.svg'

import './index.scss'

const DefaultSettings = ({
  handlePropertyId,
  orderFormatActions,
  isTemplateLoadingFirst,
  setShowIsTemplateWarningModal,
  disableAll,
}) => {
  const updateLocaleFormatActions = useStoreActions((actions) => actions.order.updateLocaleFormatActions)

  const [isSelectedValue, setIsSelectedValue] = useState('')
  const [onClickChangeRadioValue, setOnClickChangeRadioValue] = useState('')
  const [onClickClosePortalValue, setOnClickClosePortalValue] = useState('')

  const zalando = {
    zalando_ready_apparel_model: (
      <>
        {orderFormatActions?.zalando_ready_apparel_model?.label}
        <InfoModal
          labelText={orderFormatActions?.zalando_ready_apparel_model?.label}
          modalText={Translation.zalando_model_modal_text}
        />
      </>
    ),
    zalando_ready_apparel_clipping: (
      <>
        {orderFormatActions?.zalando_ready_apparel_clipping?.label}
        <InfoModal
          labelText={orderFormatActions?.zalando_ready_apparel_clipping?.label}
          modalText={Translation.zalando_nonmodel_apparel_modal_text}
        />
      </>
    ),
    zalando_ready_accessories: (
      <>
        {orderFormatActions?.zalando_ready_accessories?.label}
        <InfoModal
          labelText={orderFormatActions?.zalando_ready_accessories?.label}
          modalText={Translation.zalando_nonmodel_accessories_modal_text}
        />
      </>
    ),
  }

  const amazon = {
    amazon_ready: (
      <>
        {orderFormatActions?.amazon_ready?.label}
        <span className="price-badge">
          {orderFormatActions?.amazon_ready?.price}
        </span>
        <InfoModal
          labelText={orderFormatActions?.amazon_ready?.label}
          modalText={Translation.amazon_gallery_images_modal_text}
        />
      </>
    ),
    amazon_ready_other: (
      <>
        {orderFormatActions?.amazon_ready_other?.label}
        <span className="price-badge">
          {orderFormatActions?.amazon_ready_other?.price}
        </span>
        <InfoModal
          labelText={orderFormatActions?.amazon_ready_other?.label}
          modalText={Translation.amazon_other_views_modal_text}
        />
      </>
    ),
  }

  const ebay = {
    ebay_ready: (
      <>
        {orderFormatActions?.ebay_ready?.label}
        <span className="price-badge">
          {orderFormatActions?.ebay_ready?.price}
        </span>
        <InfoModal
          labelText={orderFormatActions?.ebay_ready?.label}
          modalText={Translation.ebay_ready_modal_text}
        />
      </>
    ),
  }

  const retail = {
    retail_ready_apparel_model: orderFormatActions.retail_ready_apparel_model.label,
    retail_ready_apparel_clipping: orderFormatActions.retail_ready_apparel_clipping.label,
    retail_ready_accessories: orderFormatActions.retail_ready_accessories.label,

  }
  const portals = [
    { name: 'zalando', key: 'portal_setting_zalando_ready', values: zalando },
    { name: 'amazon', key: 'portal_setting_amazon_ready', values: amazon },
    { name: 'ebay', key: 'portal_setting_ebay_ready', values: ebay },
    { name: 'retail', key: 'portal_setting_retail_ready', values: retail },
  ]

  useEffect(() => {
    let isAnyPortalSettingSelected = false
    portals.forEach((portalItem) => {
      Object.keys(portalItem.values).forEach((key) => {
        if (orderFormatActions[key].value) {
          setIsSelectedValue(key)
          isAnyPortalSettingSelected = true
        }
      })
    })
    if (!isAnyPortalSettingSelected) {
      setIsSelectedValue('')
    }
  }, [orderFormatActions])

  useEffect(() => {
    if (onClickClosePortalValue === null) {
      setIsSelectedValue(false)
    }
  }, [onClickClosePortalValue])

  const handleSectionTopChange = (id, value) => {
    if (isTemplateLoadingFirst) {
      setShowIsTemplateWarningModal(true)
    } else {
      // eslint-disable-next-line no-lonely-if
      if (value === false && isSelectedValue) {
        setOnClickClosePortalValue(id)
      } else {
        handlePropertyId(id, value)
      }
    }
  }

  const handleSwitchChange = (id, value, values) => {
    if (isTemplateLoadingFirst) {
      setShowIsTemplateWarningModal(true)
    } else {
      // eslint-disable-next-line no-lonely-if
      if (value === false && Object.keys(values).includes(isSelectedValue)) {
        setOnClickClosePortalValue(id)
      } else {
        updateLocaleFormatActions(
          { [id]: { ...orderFormatActions[id], value } },
        )
      }
    }
  }

  return (
    <section
      className={`new-order-section section-default-setttings ${orderFormatActions?.portal_settings?.value ? 'active' : ''}`}
    >
      <Accordion expanded={!!orderFormatActions?.portal_settings?.value} className="accordion">
        <AccordionSummary className="accordion-summary">
          <SectionTop
            showContent={!!orderFormatActions?.portal_settings?.value}
            onChange={(id, value) => handleSectionTopChange(id, value)}
            id="portal_settings"
            icon={<LayersSvg />}
            defaultChecked={orderFormatActions?.portal_settings?.value !== null}
            labelText={orderFormatActions?.portal_settings?.label}
            modalText={Translation.default_settings_modal_text}
            badgeText={orderFormatActions?.portal_settings?.price}
            disabled={disableAll}
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <div className="section-content">
            <div className="section-content-columns">
              {
                portals.map((portalItem) => (
                  <div className="section-content-column" key={portalItem.name}>
                    { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor={portalItem.name} className="column-brand">
                      <Switch
                        id={portalItem.name}
                        checked={!!orderFormatActions[portalItem.key].value || false}
                        onChange={(value) => handleSwitchChange(portalItem.key, value, portalItem.values)}
                        disabled={disableAll}
                      />
                      <img src={`/image/new-order/${portalItem.name}_logo.svg`} alt={portalItem.name} />
                    </label>
                    <div
                      className="column-content"
                      style={{ display: orderFormatActions[portalItem.key].value ? 'block' : 'none' }}
                    >
                      <RadioGroup
                        name={portalItem.name}
                        value={isSelectedValue}
                        values={portalItem.values}
                        color="gray"
                        onChange={(e) => setOnClickChangeRadioValue(e.target.value)}
                        allDisabled={disableAll}
                      />
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="section-content-info">
              <Typography variant="xs" theme="light" label={Translation.default_settings_for_portals_info_text} />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <OrderFormModal
        isShown={onClickChangeRadioValue}
        onClickOutside={() => setOnClickChangeRadioValue('')}
        title={Translation.all_settings_will_be_changed_to.replace('{id}', orderFormatActions[onClickChangeRadioValue]?.label)}
        contentText={
          onClickChangeRadioValue === 'retail_ready_apparel_model'
            || onClickChangeRadioValue === 'retail_ready_apparel_clipping'
            || onClickChangeRadioValue === 'retail_ready_accessories'
            ? (
              Translation.all_other_previously_text
            ) : (
              <>
                <span>
                  {Translation.new_order_default_settings_modal_text_top
                    .replace('{id}', orderFormatActions[onClickChangeRadioValue]?.label)}
                </span>
                {' '}
                <span>{Translation.new_order_default_settings_modal_text_bottom}</span>
              </>
            )
        }
        leftButtonClick={() => setOnClickChangeRadioValue('')}
        rightButtonClick={() => {
          handlePropertyId(onClickChangeRadioValue, onClickChangeRadioValue)
          setOnClickChangeRadioValue('')
        }}
      />
      <OrderFormModal
        isShown={onClickClosePortalValue}
        onClickOutside={() => setOnClickClosePortalValue('')}
        title={Translation.attention}
        contentText={Translation.default_settings_close_portal_message}
        leftButtonClick={() => setOnClickClosePortalValue('')}
        rightButtonClick={() => {
          handlePropertyId(onClickClosePortalValue, false)
          setOnClickClosePortalValue(null)
        }}
      />
    </section>
  )
}

DefaultSettings.propTypes = {
  handlePropertyId: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  orderFormatActions: PropTypes.objectOf(PropTypes.any),
  isTemplateLoadingFirst: PropTypes.bool,
  setShowIsTemplateWarningModal: PropTypes.func,
  disableAll: PropTypes.bool,
}

DefaultSettings.defaultProps = {
  orderFormatActions: {},
  isTemplateLoadingFirst: false,
  setShowIsTemplateWarningModal: () => { },
  disableAll: false,
}

export default DefaultSettings
