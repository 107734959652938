import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../../helpers/Translation'
import {
  VALID_FILE_EXTENSIONS,
  MEMORY_BYTE_LIMIT_UPLOAD_DOWNLOAD,
  MAX_FILES_UPLOAD_CNT,
  TEST_ORDER_MAX_FILES_UPLOAD_CNT,
  FOLDER_TYPES,
  PX_TO_REM,
} from '../../../../helpers/Constants'

import Typography from '../../../../components/Typography'
import Button from '../../../../components/Button'
import Textarea from '../../../../components/Textarea'
import Modal from '../../../../components/Modal'
import RadioGroup from '../../../../components/RadioGroup'

import { ReactComponent as PlusIcon } from '../../../../svg/white_plus.svg'
import { ReactComponent as TrashSvg } from '../../../../svg/n-trash.svg'
import { ReactComponent as CheckCircleSvg } from '../../../../svg/check_circle.svg'
import { ReactComponent as FilePictureSvg } from '../../../../svg/file_picture.svg'
import { ReactComponent as CloseSvg } from '../../../../svg/close.svg'
import { ReactComponent as ArrowDown } from '../../../../svg/arrow_down.svg'
import { ReactComponent as FolderIcon } from '../../../../svg/folder.svg'
import { ReactComponent as InfoSvg } from '../../../../svg/info.svg'

import './index.scss'

const ImagesUpload = ({
  templateId,
  parallelTemplateId,
  webImages,
  csvImages,
  onImageChange,
  folderType,
  testPic,
  isTestOrder,
}) => {
  const updateSnackbarState = useStoreActions((state) => state.global.updateSnackbarState)
  const orderFolders = useStoreState((state) => state.ftp.orderFolders)
  const user = useStoreState((state) => state.user.user)
  const folderId = useStoreState((state) => state.order.folderId)

  const uploadImage = useStoreActions((actions) => actions.order.uploadImage)
  const deleteImage = useStoreActions((actions) => actions.order.deleteImage)
  const getOrderFormPricing = useStoreActions((actions) => actions.order.getOrderFormPricing)
  const getSelectedTestImage = useStoreActions((actions) => actions.order.getSelectedTestImage)
  const getCsvUpload = useStoreActions((actions) => actions.order.getCsvUpload)
  const updateTemplateValue = useStoreActions((actions) => actions.order.updateTemplateValue)
  const setFolderType = useStoreActions((actions) => actions.order.setFolderType)
  const getOrderFtpFolders = useStoreActions((actions) => actions.ftp.getOrderFtpFolders)
  const setFolderId = useStoreActions((actions) => actions.order.setFolderId)

  const addImageInput = useRef(null)

  const [activeTab, setActiveTab] = useState()
  const [localWebImages, setLocalWebImages] = useState([])
  const [localCSVImages, setLocalCSVImages] = useState([])
  const [isUploadImagesLoading, setIsUploadImagesLoading] = useState(false)
  const [isSelectTestImageEnabled, setIsSelectTestImageEnabled] = useState(false)
  const [selectedTestImage, setSelectedTestImage] = useState(null)
  const [showImages, setShowImages] = useState(true)
  const [removeAllImagesType, setRemoveAllImagesType] = useState(null)
  const [csvImageLinks, setCsvImageLinks] = useState([])
  const [selectedFtpFolder, setSelectedFtpFolder] = useState(null)
  const [dragging, setDragging] = useState(false)
  const [isOverDragging, setIsOverDragging] = useState(false)

  const tabs = [
    {
      id: FOLDER_TYPES.WEB,
      label: Translation.web_upload,
      show: true,
    },
    {
      id: FOLDER_TYPES.FTP,
      label: FOLDER_TYPES.FTP.toUpperCase(),
      show: user?.is_live_ftp_order && !isTestOrder,
    },
    {
      id: FOLDER_TYPES.CSV,
      label: FOLDER_TYPES.CSV.toUpperCase(),
      show: !isTestOrder,
    },
    {
      id: FOLDER_TYPES.WETRANSFER,
      label: FOLDER_TYPES.WETRANSFER.toUpperCase(),
      show: !isTestOrder,
    },
  ]

  const ftpTransformedFtpData = () => {
    const tempFtpData = {}
    const tempFtpDisabledData = []
    orderFolders.map((folder) => {
      tempFtpData[folder.folder_id] = (
        <div key={folder.folder_id} className="folder-item">
          <FolderIcon className="folder-icon" />
          <Typography variant="xs" inline label={folder.input_folder_name} />
          &nbsp;
          <Typography
            variant="xs"
            inline
            font="semibold"
          >
            (
            {folder.image_count}
            {' '}
            {Translation.images}
            {' '}
            )
          </Typography>
        </div>
      )
      if (folder.image_count === 0) {
        tempFtpDisabledData.push(folder.folder_id)
      }
      return true
    })

    return {
      data: tempFtpData,
      disabledData: tempFtpDisabledData,
    }
  }

  const handleChangeFtpFolder = async (e) => {
    setSelectedFtpFolder(e.target.value)
    await updateTemplateValue({ template_id: templateId, id: 'folder_id', value: e.target.value })
    getOrderFormPricing({ template_id: templateId })
    // get the image count from orderFolders by using the folder_id and add fake images to onImageChange
    const folder = orderFolders.find((item) => item.folder_id === e.target.value)
    const fakeImages = []
    for (let i = 0; i < folder.image_count; i += 1) {
      fakeImages.push({
        name: `image_${i + 1}.jpg`,
      })
    }
    onImageChange(fakeImages)
  }

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (webImages?.length > 0) {
      // eslint-disable-next-line react/prop-types
      setLocalWebImages(webImages)
    }
    // eslint-disable-next-line react/prop-types
  }, [webImages?.length])

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (csvImages?.length > 0) {
      // eslint-disable-next-line react/prop-types
      setLocalCSVImages(csvImages)
    }
    // eslint-disable-next-line react/prop-types
  }, [csvImages?.length])

  useEffect(() => {
    if (testPic) {
      // eslint-disable-next-line react/prop-types
      setSelectedTestImage(testPic)
    }
  }, [testPic])

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (folderType) {
      // eslint-disable-next-line react/prop-types
      setActiveTab(folderType)
      setFolderType(folderType)
    }
  }, [folderType])

  useEffect(() => {
    if (orderFolders !== null) {
      orderFolders.map((folder) => {
        if (folderId === parseInt(folder.folder_id, 10) && folder.image_count > 0) {
          setSelectedFtpFolder(folderId)
          getOrderFormPricing({ template_id: templateId })
          // fake images
          const fakeImages = []
          for (let i = 0; i < folder.image_count; i += 1) {
            fakeImages.push({
              name: `image_${i + 1}.jpg`,
            })
          }
          onImageChange(fakeImages)
        }
        return true
      })
    }
  }, [orderFolders, folderId])

  const handleTabChange = async (tabId) => {
    // eslint-disable-next-line react/prop-types
    if (templateId) {
      setActiveTab(tabId)

      await updateTemplateValue({ template_id: templateId, id: 'folder_type', value: tabId })

      setFolderType(tabId)

      if (tabId === FOLDER_TYPES.WEB) {
        onImageChange(localWebImages)
      }

      if (tabId === FOLDER_TYPES.FTP && folderId === null) {
        onImageChange([])
        return
      }

      if (tabId === FOLDER_TYPES.CSV) {
        onImageChange(localCSVImages)
      }

      if (tabId === FOLDER_TYPES.WETRANSFER) {
        // create on fake image
        onImageChange([{
          name: 'image_1.jpg',
        }])
      }

      getOrderFormPricing({ template_id: templateId })
    }
  }

  useEffect(() => {
    if (activeTab === FOLDER_TYPES.FTP) {
      getOrderFtpFolders('order')
    } else {
      setFolderId(null)
      setSelectedFtpFolder(null)
    }
  }, [activeTab])

  const uploadImagesSequentially = async (files) => {
    const uploadedImages = []
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      const fileExtension = file.name.toLowerCase().split('.').pop()
      const { size } = file
      // check extension
      if (!VALID_FILE_EXTENSIONS.includes(fileExtension.toLowerCase())) {
        updateSnackbarState({
          isOpen: true,
          message: Translation.format_is_not_supported,
          type: 'error',
        })
      } else if (size > MEMORY_BYTE_LIMIT_UPLOAD_DOWNLOAD) {
        updateSnackbarState({
          isOpen: true,
          message: Translation.image_size_warning_text,
          type: 'error',
        })
      } else {
        // first remove if any image with same name exists
        const fileExists = localWebImages.find((item) => item.name === file.name)
        // this is a hack to remove the previous image with same name
        setLocalWebImages((prev) => prev.filter((item) => item.name !== file.name))
        setLocalWebImages((prev) => [...prev, file])
        setIsUploadImagesLoading(true)

        // eslint-disable-next-line no-await-in-loop
        const response = await uploadImage({
          file,
          // eslint-disable-next-line react/prop-types
          template_id: templateId,
        })
        if (response && !fileExists) {
          uploadedImages.push(file)
        }

        setIsUploadImagesLoading(false)
      }
    }
    return uploadedImages
  }

  const handleImageUpload = async (e) => {
    const { files } = e.currentTarget

    if ([...localWebImages, ...files].length > (isTestOrder ? TEST_ORDER_MAX_FILES_UPLOAD_CNT : MAX_FILES_UPLOAD_CNT)) {
      updateSnackbarState({
        isOpen: true,
        message: Translation.max_images_warning_text
          .replace('{image_count}', isTestOrder ? TEST_ORDER_MAX_FILES_UPLOAD_CNT : MAX_FILES_UPLOAD_CNT),
        type: 'error',
      })
    } else {
      // TODO: We should disable the button or section etc. until the response is received
      const filesUploaded = await uploadImagesSequentially(Array.from(files))
      // eslint-disable-next-line react/prop-types
      if ([...localWebImages, ...filesUploaded].length > 0) {
        getOrderFormPricing({ template_id: templateId })
      }
      onImageChange([...localWebImages, ...filesUploaded])
    }
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
    setIsOverDragging(true)
    setDragging(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setTimeout(() => {
      setIsOverDragging(false)
    }, 100)
    if (!isOverDragging) {
      setDragging(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    if (e.dataTransfer.files.length > 0) {
      const temp = {
        currentTarget: {
          files: e.dataTransfer.files,
        },
      }
      handleImageUpload(temp).then(() => { })
    }
    setDragging(false)
  }

  const handleDeleteImage = async (name, typeFolder = FOLDER_TYPES.WEB) => {
    const params = {
      // eslint-disable-next-line react/prop-types
      template_id: templateId,
      folder_type: typeFolder,
      is_test_image: name === selectedTestImage,
      files: [name],
    }

    await deleteImage(params)
    // eslint-disable-next-line react/prop-types
    await getOrderFormPricing({ template_id: templateId })
    if (typeFolder === FOLDER_TYPES.WEB) {
      setLocalWebImages((prev) => prev.filter((item) => item.name !== name))
      onImageChange(localWebImages.filter((item) => item.name !== name))
    }

    if (typeFolder === FOLDER_TYPES.CSV) {
      setLocalCSVImages((prev) => prev.filter((item) => item.name !== name))
      onImageChange(localCSVImages.filter((item) => item.name !== name))
    }
  }

  const formatImageSize = (size) => {
    if (size < 1024) {
      return `${size} B`
    }
    if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`
    }
    return `${(size / (1024 * 1024)).toFixed(2)} MB`
  }

  const handleDeleteAllImages = async (id, typeFolder = FOLDER_TYPES.WEB) => {
    const params = {
      // eslint-disable-next-line react/prop-types
      template_id: id,
      folder_type: typeFolder,
      is_test_image: false,
      files: [],
    }
    // TODO: We should disable the button or section etc. until the response is received
    await deleteImage(params)
    setLocalWebImages([])
    setLocalCSVImages([])
    setIsSelectTestImageEnabled(false)
    setSelectedTestImage(null)
    onImageChange([])
  }

  const handleSelectTestImage = async (name) => {
    // eslint-disable-next-line react/prop-types
    await getSelectedTestImage({ template_id: templateId, filename: name ?? '' })
  }

  const handleCsvImageUpload = async () => {
    if (!csvImageLinks) return
    // for each image link, we should upload sequentially
    const splittedLinks = csvImageLinks.split('\n')
    // eslint-disable-next-line no-restricted-syntax
    for (const link of splittedLinks) {
      const params = {
        template_id: templateId,
        files: [link],
      }

      // eslint-disable-next-line no-await-in-loop
      const resultCsvUpload = await getCsvUpload(params)
      if (resultCsvUpload.success) {
        setCsvImageLinks('')
        setLocalCSVImages(resultCsvUpload.csv_images)
        onImageChange(resultCsvUpload.csv_images)
      }
    }
    getOrderFormPricing({ template_id: templateId })
  }

  return (
    <div>
      {
        localWebImages.length > TEST_ORDER_MAX_FILES_UPLOAD_CNT && isTestOrder ? (
          <div className="test-order-information">
            <div className="test-order-information-title">
              <InfoSvg />
              <Typography variant="h6" font="semibold" label={Translation.information} />
            </div>
            <div className="test-order-information-text">
              <Typography variant="xs" label={Translation.image_upload_information_max_description} />
            </div>
            <div className="test-order-information-button">
              <Button
                label={Translation.delete_all_images_from_new_order}
                onClick={() => handleDeleteAllImages(parallelTemplateId)}
              />
            </div>
          </div>
        ) : (
          <div className="section-images-upload">
            <div className="section-tabs">
              {
                tabs.map((tab) => (
                  tab.show && (
                    <button
                      key={tab.id}
                      type="button"
                      className={`section-tab ${activeTab === tab.id ? 'active' : ''}`}
                      onClick={() => handleTabChange(tab.id)}
                    >
                      <Typography variant="xs" label={tab.label} />
                    </button>
                  )
                ))
              }
            </div>
            <section
              className={`images-upload-content upload-img-wrapper ${showImages ? 'fill' : ''}`}
              style={{ display: activeTab !== FOLDER_TYPES.WEB ? 'none' : '' }}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleDrop}
            >
              <div className="img-upload-input-wrapper" style={{ display: dragging ? 'block' : 'none' }}>
                <div className="img-upload-text">
                  <Typography variant="h5" font="medium" label={Translation.drag_drop_images_here} />
                </div>
                <input
                  type="file"
                  ref={addImageInput}
                  className="img-upload-input"
                  onChange={handleImageUpload}
                  multiple
                  accept={VALID_FILE_EXTENSIONS.split(',').map((item) => `.${item}`).join(',')}
                />
              </div>
              <div className="upload-top">
                <div className="upload-top-left">
                  {
                    localWebImages.length > 1 && !isTestOrder && (
                      <>
                        <button
                          type="button"
                          className={`${isSelectTestImageEnabled ? 'active' : ''}`}
                          onClick={() => setIsSelectTestImageEnabled(!isSelectTestImageEnabled)}
                        >
                          <FilePictureSvg />
                          <Typography variant="xs" font="semibold" label={Translation.select_test_image} />
                        </button>
                        <button type="button" onClick={() => setRemoveAllImagesType(FOLDER_TYPES.WEB)}>
                          <TrashSvg />
                          <Typography variant="xs" font="semibold" label={Translation.remove_all_images} />
                        </button>
                      </>
                    )
                  }
                </div>
                <div className="upload-top-addBtn">
                  <Button
                    type="fit-content"
                    label={(
                      <>
                        <PlusIcon />
                        <Typography variant="xs" font="semibold" inlineBlock label={Translation.add_images} />
                      </>
                    )}
                    onClick={() => addImageInput.current.click()}
                  />
                </div>
                <div className="upload-top-right">
                  {
                    localWebImages.length > 0 && (
                      <>
                        <Typography
                          variant="xs"
                          font="semibold"
                          label={`${localWebImages.length}
                      ${localWebImages.length === 1 ? Translation.image_uploaded : Translation.images_uploaded}`}
                        />
                        <button
                          type="button"
                          onClick={() => setShowImages(!showImages)}
                          className={`${showImages ? 'rot-180' : ''}`}
                        >
                          <ArrowDown />
                        </button>
                      </>
                    )
                  }
                </div>
              </div>
              {
                (localWebImages.length > 0 && showImages) ? (
                  <ul className={`upload-images ${isSelectTestImageEnabled ? 'enabled-select-test-image' : ''}`}>
                    {
                      Array.from(localWebImages).map((image, index) => (
                        <li key={`${image.name}-${Math.random()}`}>
                          <button
                            type="button"
                            className="upload-img-binIcon"
                            onClick={() => handleDeleteImage(image.name)}
                          >
                            <TrashSvg />
                          </button>
                          {
                            (index + 1 === localWebImages.length) && isUploadImagesLoading ? (
                              <div className="upload-img-progressWrapper">
                                <div className="upload-img-progress" style={{ width: '50%' }} />
                              </div>
                            ) : (
                              <button type="button" className="upload-img-checkIcon">
                                <CheckCircleSvg />
                              </button>
                            )
                          }
                          <button
                            type="button"
                            className="image-row"
                            onClick={isSelectTestImageEnabled ? () => {
                              handleSelectTestImage(image.name).then(() => { })
                              setSelectedTestImage(image.name)
                              setIsSelectTestImageEnabled(false)
                            } : () => { }}
                          >
                            {image.name}
                            {' - '}
                            {formatImageSize(image.size)}
                            {
                              selectedTestImage === image.name && (
                                <div className="image-row-badge">
                                  <Typography
                                    fontSize={PX_TO_REM['10']}
                                    label={Translation.test_image}
                                  />
                                  <button
                                    type="button"
                                    className="badge-delete"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      setIsSelectTestImageEnabled(false)
                                      handleSelectTestImage(null).then(() => { })
                                      setSelectedTestImage(null)
                                    }}
                                  >
                                    <CloseSvg />
                                  </button>
                                </div>
                              )
                            }
                          </button>
                        </li>
                      ))
                    }
                  </ul>
                ) : (
                  showImages && (
                    <div className="upload-input-text">
                      <Typography variant="xl" font="semibold" label={Translation.drag_drop_images_here} />
                    </div>
                  )
                )
              }
              <div className="upload-warning-text">
                <Typography
                  variant="xs"
                  label={
                    `${Translation.attention}: 
                 ${Translation.you_can_upload_up_to_images
                      .replace('{image_count}', isTestOrder ? TEST_ORDER_MAX_FILES_UPLOAD_CNT : MAX_FILES_UPLOAD_CNT)}`
                  }
                />
              </div>
            </section>
            <section className="images-upload-content" style={{ display: activeTab !== FOLDER_TYPES.FTP ? 'none' : '' }}>
              <div className="content-descriptions">
                <div className="descriptions-title">
                  <Typography variant="h6" font="semibold" label={Translation.ftp_folders} />
                </div>
                <div className="descriptions-text">
                  <Typography variant="xs" label={Translation.ftp_section_text} />
                </div>
                {
                  orderFolders !== null && Object.keys(ftpTransformedFtpData().data).length > 0 && (
                    <div className="folder-list">
                      {/* TODO Disabled data is also rendered when empty.  */}
                      <RadioGroup
                        name="folder-list"
                        value={selectedFtpFolder}
                        values={ftpTransformedFtpData().data}
                        onChange={handleChangeFtpFolder}
                        color="gray"
                        disabledData={ftpTransformedFtpData().disabledData}
                      />
                    </div>
                  )
                }
              </div>
            </section>
            <section className="images-upload-content" style={{ display: activeTab !== FOLDER_TYPES.CSV ? 'none' : '' }}>
              <div className="content-descriptions">
                <div className="descriptions-title">
                  <Typography variant="h6" font="semibold" label={Translation.add_images_from_csv_and_excel} />
                </div>
                <div className="descriptions-text">
                  <Typography variant="xs" label={Translation.add_images_text} />
                </div>
                <div className="descriptions-title">
                  <Typography variant="h6" font="semibold" label={Translation.paste_from_excel} />
                </div>
                <div className="descriptions-text">
                  <Typography variant="xs" label={Translation.paste_from_excel_text} />
                </div>
                {
                  localCSVImages.length > 1 && (
                    <button
                      type="button"
                      className="csv-remove-all-images"
                      onClick={() => setRemoveAllImagesType(FOLDER_TYPES.CSV)}
                    >
                      <TrashSvg />
                      <Typography variant="xs" font="medium" label={Translation.remove_all_images} />
                    </button>
                  )
                }
                <div className="descriptions-textarea">
                  <Textarea
                    placeholder={Translation.paste_from_excel}
                    onChange={(e) => setCsvImageLinks(e.target.value)}
                    value={csvImageLinks}
                    onBlur={handleCsvImageUpload}
                  />
                </div>
                <div className="csv-images">
                  <Typography variant="xs" font="medium" label={Translation.images_found_in_csv_file} />
                  <ul>
                    {
                      // eslint-disable-next-line react/prop-types
                      localCSVImages?.map((image) => (
                        <li key={image.name}>
                          <Typography label={image.name} />
                          <button type="button" onClick={() => handleDeleteImage(image.name, FOLDER_TYPES.CSV)}>
                            <TrashSvg />
                          </button>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            </section>
            <section className="images-upload-content" style={{ display: activeTab !== FOLDER_TYPES.WETRANSFER ? 'none' : '' }}>
              <div className="content-descriptions">
                <div className="descriptions-text">
                  <Typography variant="xs" label={Translation.wetransfer_top_text} />
                </div>
                <div className="descriptions-text">
                  <Typography variant="xs" label={Translation.wetransfer_bottom_text} containsHtml />
                </div>
              </div>
            </section>
            <div className="new-order-modal">
              <Modal
                hasCloseIcon
                isShown={removeAllImagesType}
                onClickCloseIcon={() => setRemoveAllImagesType('')}
                onClickEscClose={() => setRemoveAllImagesType('')}
              >
                <div className="new-order-modal-title">
                  <Typography
                    variant="h6"
                    font="semibold"
                    lineHeight={PX_TO_REM['25']}
                    label={Translation.attention}
                  />
                </div>
                <div className="new-order-modal-content">
                  <Typography variant="xs" label={Translation.remove_all_images_warning_text} />
                </div>
                <div className="new-order-modal-buttons">
                  <Button type="contained" label={Translation.no} onClick={() => setRemoveAllImagesType('')} />
                  <Button
                    label={Translation.yes}
                    onClick={() => {
                      handleDeleteAllImages(templateId, removeAllImagesType).then(() => { })
                      setRemoveAllImagesType('')
                    }}
                  />
                </div>
              </Modal>
            </div>
          </div>
        )
      }

    </div>
  )
}

ImagesUpload.propTypes = {
  templateId: PropTypes.number.isRequired,
  parallelTemplateId: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  webImages: PropTypes.arrayOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  csvImages: PropTypes.arrayOf(PropTypes.any),
  onImageChange: PropTypes.func.isRequired,
  folderType: PropTypes.string,
  testPic: PropTypes.string,
  isTestOrder: PropTypes.bool,
}

ImagesUpload.defaultProps = {
  parallelTemplateId: null,
  webImages: [],
  csvImages: [],
  folderType: null,
  testPic: null,
  isTestOrder: false,
}

export default ImagesUpload
