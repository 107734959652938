import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../../helpers/Translation'

import Modal from '../../../../components/Modal'
import Button from '../../../../components/Button'
import Typography from '../../../../components/Typography'

const OrderFormModal = ({
  isShown,
  onClickOutside,
  title,
  contentText,
  leftButtonClick,
  rightButtonClick,
}) => {
  const onClickOutsideModal = (e) => {
    if (e.target?.outerHTML?.includes('modal--wrap')) {
      onClickOutside()
    }
  }

  return (
    <div className="new-order-modal">
      <Modal
        hasCloseIcon
        isShown={isShown}
        onClickOutside={onClickOutsideModal}
        onClickCloseIcon={() => onClickOutside()}
        onClickEscClose={() => onClickOutside()}
      >
        <div className="new-order-modal-title">
          <Typography variant="h6" font="semibold" label={title} />
        </div>
        <div className="new-order-modal-content">
          <Typography
            variant="xs"
            label={contentText}
          />
        </div>
        <div className="new-order-modal-buttons">
          <Button type="contained" label={Translation.no} onClick={leftButtonClick} />
          <Button label={Translation.yes} onClick={rightButtonClick} />
        </div>
      </Modal>
    </div>
  )
}

OrderFormModal.propTypes = {
  isShown: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onClickOutside: PropTypes.func,
  title: PropTypes.string,
  contentText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  leftButtonClick: PropTypes.func,
  rightButtonClick: PropTypes.func,
}

OrderFormModal.defaultProps = {
  isShown: false,
  onClickOutside: () => { },
  title: '',
  contentText: '',
  leftButtonClick: () => { },
  rightButtonClick: () => { },
}

export default OrderFormModal
